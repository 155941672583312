import Aura from "@primevue/themes/aura";
import { definePreset } from "@primevue/themes";

const indigo = {
  semantic: {
    primary: {
      50: "{indigo.50}",
      100: "{indigo.100}",
      200: "{indigo.200}",
      300: "{indigo.300}",
      400: "{indigo.400}",
      500: "{indigo.500}",
      600: "{indigo.600}",
      700: "{indigo.700}",
      800: "{indigo.800}",
      900: "{indigo.900}",
      950: "{indigo.950}",
    },
  },
};

const orange = {
  semantic: {
    primary: {
      50: "{orange.50}",
      100: "{orange.100}",
      200: "{orange.200}",
      300: "{orange.300}",
      400: "{orange.400}",
      500: "{orange.500}",
      600: "{orange.600}",
      700: "{orange.700}",
      800: "{orange.800}",
      900: "{orange.900}",
      950: "{orange.950}",
    },
  },
};

export default {
  theme: {
    preset: definePreset(Aura, orange),
    options: {
      darkmodeSelector: ".p-dark-mode",
    },
    cssLayer: {
      name: "primevue",
      order: "tailwind-base, primevue, tailwind-utilities",
    },
  },
  ripple: true,
  locale: {
    clear: "クリア",
    apply: "適用",
    addRule: "条件を追加",
    removeRule: "条件を削除",
    choose: "選択",
    upload: "アップロード",
    cancel: "キャンセル",

    dayNames: ["日", "月", "火", "水", "木", "金", "土"],
    dayNamesShort: ["日", "月", "火", "水", "木", "金", "土"],
    dayNamesMin: ["日", "月", "火", "水", "木", "金", "土"],
    monthNames: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    monthNamesShort: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    chooseYear: "年を選択",
    chooseMonth: "月を選択",
    chooseDate: "日付を選択",
    today: "今日",
    passwordPrompt: "パスワードを入力してください",
    searchMessage: "{0}件の結果が見つかりました",
    selectionMessage: "{0}件選択されました",
    emptySelectionMessage: "選択された項目はありません",
    emptySearchMessage: "結果が見つかりません",
    noFileChosenMessage: "ファイルが選択されていません",
    emptyMessage: "候補がありません",
  },
};
